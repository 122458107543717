<script lang="ts">
    import type { Breakpoints, ColumsWidth, Element } from '../types'
    import { concatClasses } from '../utils'

    let classNames = ''
    export { classNames as class }
    export let element: Element = 'div'
    export let xs: ColumsWidth = ''
    export let sm: ColumsWidth = ''
    export let md: ColumsWidth = ''
    export let lg: ColumsWidth = ''
    export let xl: ColumsWidth = ''
    export let xxl: ColumsWidth = ''

    // Has to be hardcoded like that beacasue purgecss
    function getWidthClass(breakpoint: Breakpoints, columns: ColumsWidth) {
        switch (breakpoint) {
            case 'xs':
                switch (columns) {
                    case '1':
                        return 'w-1/12'
                    case '2':
                        return 'w-2/12'
                    case '3':
                        return 'w-3/12'
                    case '4':
                        return 'w-4/12'
                    case '5':
                        return 'w-5/12'
                    case '6':
                        return 'w-6/12'
                    case '7':
                        return 'w-7/12'
                    case '8':
                        return 'w-8/12'
                    case '9':
                        return 'w-9/12'
                    case '10':
                        return 'w-10/12'
                    case '11':
                        return 'w-11/12'
                    case '':
                        return 'w-full'
                    default:
                        'w-full'
                }
                break
            case 'sm':
                switch (columns) {
                    case '1':
                        return 'sm:w-1/12'
                    case '2':
                        return 'sm:w-2/12'
                    case '3':
                        return 'sm:w-3/12'
                    case '4':
                        return 'sm:w-4/12'
                    case '5':
                        return 'sm:w-5/12'
                    case '6':
                        return 'sm:w-6/12'
                    case '7':
                        return 'sm:w-7/12'
                    case '8':
                        return 'sm:w-8/12'
                    case '9':
                        return 'sm:w-9/12'
                    case '10':
                        return 'sm:w-10/12'
                    case '11':
                        return 'sm:w-11/12'
                    default:
                        ''
                }
                break
            case 'md':
                switch (columns) {
                    case '1':
                        return 'md:w-1/12'
                    case '2':
                        return 'md:w-2/12'
                    case '3':
                        return 'md:w-3/12'
                    case '4':
                        return 'md:w-4/12'
                    case '5':
                        return 'md:w-5/12'
                    case '6':
                        return 'md:w-6/12'
                    case '7':
                        return 'md:w-7/12'
                    case '8':
                        return 'md:w-8/12'
                    case '9':
                        return 'md:w-9/12'
                    case '10':
                        return 'md:w-10/12'
                    case '11':
                        return 'md:w-11/12'
                    default:
                        ''
                }
                break
            case 'lg':
                switch (columns) {
                    case '1':
                        return 'lg:w-1/12'
                    case '2':
                        return 'lg:w-2/12'
                    case '3':
                        return 'lg:w-3/12'
                    case '4':
                        return 'lg:w-4/12'
                    case '5':
                        return 'lg:w-5/12'
                    case '6':
                        return 'lg:w-6/12'
                    case '7':
                        return 'lg:w-7/12'
                    case '8':
                        return 'lg:w-8/12'
                    case '9':
                        return 'lg:w-9/12'
                    case '10':
                        return 'lg:w-10/12'
                    case '11':
                        return 'lg:w-11/12'
                    default:
                        ''
                }
                break
            case 'xl':
                switch (columns) {
                    case '1':
                        return 'xl:w-1/12'
                    case '2':
                        return 'xl:w-2/12'
                    case '3':
                        return 'xl:w-3/12'
                    case '4':
                        return 'xl:w-4/12'
                    case '5':
                        return 'xl:w-5/12'
                    case '6':
                        return 'xl:w-6/12'
                    case '7':
                        return 'xl:w-7/12'
                    case '8':
                        return 'xl:w-8/12'
                    case '9':
                        return 'xl:w-9/12'
                    case '10':
                        return 'xl:w-10/12'
                    case '11':
                        return 'xl:w-11/12'
                    default:
                        ''
                }
                break
            case 'xxl':
                switch (columns) {
                    case '1':
                        return 'xxl:w-1/12'
                    case '2':
                        return 'xxl:w-2/12'
                    case '3':
                        return 'xxl:w-3/12'
                    case '4':
                        return 'xxl:w-4/12'
                    case '5':
                        return 'xxl:w-5/12'
                    case '6':
                        return 'xxl:w-6/12'
                    case '7':
                        return 'xxl:w-7/12'
                    case '8':
                        return 'xxl:w-8/12'
                    case '9':
                        return 'xxl:w-9/12'
                    case '10':
                        return 'xxl:w-10/12'
                    case '11':
                        return 'xxl:w-11/12'
                    default:
                        ''
                }
                break
            default:
                return ''
        }
        return ''
    }

    $: classes = concatClasses([
        'col',
        getWidthClass('xs', xs),
        sm ? getWidthClass('sm', sm) : '',
        md ? getWidthClass('md', md) : '',
        lg ? getWidthClass('lg', lg) : '',
        xl ? getWidthClass('xl', xl) : '',
        xxl ? getWidthClass('xxl', xxl) : '',
        classNames,
    ])
</script>

<svelte:element this={element} class={classes} {...$$restProps}>
    <slot />
</svelte:element>
